import React from 'react';
import {Box, Link} from "@chakra-ui/react";

const CommunicationCard = ({data}) => {
    const {iconClass, infoBody01, infoBody02, infoBody03, link} = data;
    if (link) {
        return <Link href={link}>
            <div className="communication">
                <div className="communication_icon">
                    <i className={iconClass}></i>
                </div>
                <Box className="info_body" pt={2}>
                    <span>{infoBody01}</span>
                    <p>{infoBody02}</p>
                    {/* <h6>{infoBody03}</h6> */}
                </Box>
            </div>
        </Link>
    }
    return (
        <div className="communication">
            <div className="communication_icon">
                <i className={iconClass}></i>
            </div>
            <div className="info_body">
                <h5>{infoBody01}</h5>
                <h6>{infoBody02}</h6>
                <h6>{infoBody03}</h6>
            </div>
        </div>
    );
};

export default CommunicationCard;