import React from "react";
import {Box, Heading, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const LeasingServices = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Comprehensive Leasing Services by Los Angeles Property Management Pros</Heading>
                <Text>
                    In the competitive real estate market of Los Angeles, effective leasing services are crucial for
                    maximizing the potential of your property investment. At Los Angeles Property Management Pros, we
                    offer a full range of leasing services designed to attract and secure reliable tenants for your
                    property. Our approach covers every aspect of the leasing process, from property marketing to lease
                    signing, ensuring a seamless experience for property owners and tenants alike.
                </Text>
                <Text>
                    Our team of experts is well-versed in the Los Angeles property market and uses this knowledge to
                    tailor leasing strategies that meet the specific needs of your property. We understand that each
                    property is unique, and our services are designed to highlight its individual features and attract
                    the right tenants.
                </Text>
                <Heading as={'h3'}>Detailed Leasing Services for Optimal Tenant Placement</Heading>
                <UnorderedList spacing={3}>
                    <ListItem><b>Effective Property Marketing:</b> We list your property on top real estate platforms,
                        ensuring it reaches a broad audience of potential tenants. Our marketing strategies are tailored
                        to showcase your property's best features, attracting quality tenants who are a perfect fit for
                        your investment.</ListItem>
                    <ListItem><b>Skilled Lease Negotiation:</b> Our leasing experts handle all negotiations, working to
                        secure lease terms that are beneficial for you. We focus on creating agreements that protect
                        your interests while being fair and attractive to prospective tenants.</ListItem>
                    <ListItem><b>Meticulous Contract Management:</b> We manage all lease documents with the utmost
                        attention to detail, ensuring they are legally sound and comprehensive. Our team stays updated
                        on the latest legal requirements and regulations, providing peace of mind that your lease
                        agreements are in compliance.</ListItem>
                    <ListItem><b>Streamlined Move-In/Move-Out Processes:</b> Our team efficiently handles the move-in
                        and move-out processes, including thorough inspections and detailed documentation. This ensures
                        that your property is well-maintained and ready for new tenants, reducing vacancy
                        periods.</ListItem>
                </UnorderedList>
                <Text>
                    The leasing process is more than just finding a tenant; it's about creating a lasting relationship
                    that benefits both the property owner and the tenant. Our leasing services include ongoing support
                    and communication, ensuring that tenants feel welcome and property owners are kept informed
                    throughout the tenancy.
                </Text>
                <Text>
                    In addition to these core services, we also provide tenant screening, which includes background
                    checks, credit evaluations, and rental history reviews. This thorough screening process ensures that
                    we place reliable and responsible tenants in your property, reducing the risk of rental defaults and
                    maintaining the integrity of your investment.
                </Text>
                <Text>
                    Our leasing services extend to property showings, where we personally showcase your property to
                    prospective tenants. We highlight the features and benefits of your property, addressing any
                    questions or concerns they may have. This personal touch not only helps in securing tenants but also
                    in building a positive initial relationship.
                </Text>
                <Text>
                    At Los Angeles Property Management Pros, we understand the importance of a well-executed leasing
                    strategy. Our comprehensive leasing services are designed to make the process efficient,
                    transparent, and successful. We take pride in our ability to match the right tenants with the right
                    property, creating value for property owners and a comfortable living environment for tenants.
                </Text>
                <Text>
                    Choose us for leasing services that are tailored to the unique demands of the Los Angeles property
                    market. Our expertise, combined with our commitment to quality and customer service, makes us the
                    ideal partner for your property leasing needs. With our team on your side, you can rest assured that
                    your property is in capable hands, poised to generate optimal returns and attract quality tenants.
                </Text>
                <Text>
                    Partner with Los Angeles Property Management Pros for your leasing needs and experience the benefits
                    of professional, efficient, and effective leasing services. Our team is ready to assist you in
                    maximizing your property’s potential and achieving your investment goals. Contact us today to learn
                    more about how our leasing services can enhance the value and appeal of your property in the
                    competitive Los Angeles rental market.
                </Text>
            </Stack>
        </Box>
    )
}