import {Box, Heading, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const MaintenanceServices = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Comprehensive Maintenance Services by Los Angeles Property Management Pros</Heading>
                <Text>
                    At Los Angeles Property Management Pros, we recognize that maintaining a property's condition is
                    crucial for preserving its value and appeal. Our extensive range of maintenance services ensures
                    that every property we manage in Los Angeles, CA, remains in top-notch condition. From regular
                    landscaping to handling emergency repairs, our dedicated maintenance crew is always on standby to
                    keep your investment safe, functional, and attractive.
                </Text>
                <Text>
                    Our approach to property maintenance is proactive and meticulous. We believe in preventing issues
                    before they arise, saving property owners time and money in the long run. Our team consists of
                    skilled professionals who understand the specific maintenance needs of properties in Los Angeles,
                    ensuring that your investment receives the best care possible.
                </Text>
                <Heading as={'h4'}>Detailed Maintenance Services for Every Need</Heading>
                <UnorderedList spacing={3}>
                    <ListItem><b>Thorough Regular Inspections:</b> We conduct routine inspections to identify and
                        address maintenance issues early. Our inspections cover everything from structural integrity to
                        systems functionality, ensuring that every aspect of your property is in excellent
                        condition.</ListItem>
                    <ListItem><b>Responsive Emergency Repairs:</b> Our maintenance team is available 24/7 to respond to
                        urgent repair needs. Whether it's a plumbing issue or electrical fault, we act swiftly to
                        resolve problems, minimizing any inconvenience to tenants and protecting the property from
                        further damage.</ListItem>
                    <ListItem><b>Unwavering Quality Assurance:</b> We hold our maintenance work to the highest
                        standards. Every repair and maintenance task is carried out with precision and care, using
                        quality materials and reliable techniques. Our commitment to quality ensures that your property
                        remains a premium offering in the Los Angeles real estate market.</ListItem>
                    <ListItem><b>Efficient Vendor Management:</b> Managing third-party vendors can be a complex task. We
                        take this burden off your shoulders by overseeing all vendor-related activities. From
                        negotiating contracts to supervising on-site work, we ensure that all external services meet our
                        high standards and align with your property's needs.</ListItem>
                </UnorderedList>
                <Text>
                    Our maintenance services are not just about fixing problems; they are about enhancing the overall
                    value and longevity of your property. In the competitive Los Angeles property market, maintaining a
                    property in excellent condition is key to attracting and retaining quality tenants. By choosing Los
                    Angeles Property Management Pros, you ensure that your property stands out for its quality and
                    upkeep.
                </Text>
                <Text>
                    We tailor our maintenance services to the specific requirements of each property. Whether your
                    property needs specialized landscaping, regular cleaning, or periodic system upgrades, our team has
                    the expertise and resources to manage it all. Our comprehensive approach covers both the interior
                    and exterior of the property, ensuring that every corner reflects our commitment to excellence.
                </Text>
                <Text>
                    In addition to routine maintenance and emergency repairs, we offer a range of preventive measures.
                    These include regular system checks, seasonal preparations (such as HVAC servicing before summer or
                    winter), and upgrading security features. Our preventive maintenance not only enhances the
                    functionality and safety of your property but also contributes to its aesthetic appeal.
                </Text>
                <Text>
                    Partner with Los Angeles Property Management Pros for your maintenance needs and experience the ease
                    and confidence of having your property cared for by experts. Our maintenance services are an
                    integral part of our comprehensive property management solutions, designed to provide you with peace
                    of mind and maximize the return on your investment. Let us handle the day-to-day maintenance tasks
                    while you enjoy the benefits of owning a well-maintained and prosperous property in Los Angeles.
                </Text>
                <Text>
                    Our commitment to your property’s maintenance is unwavering. We not only respond to maintenance
                    requests but also anticipate potential issues, addressing them proactively. This forward-thinking
                    approach to property maintenance sets us apart and ensures that your property remains a sought-after
                    location for tenants and a valuable asset for you.
                </Text>
                <Text>
                    Embrace the benefits of comprehensive and professional maintenance services with Los Angeles
                    Property Management Pros. Our team is ready to provide top-tier maintenance support, keeping your
                    property in pristine condition and ensuring its success in the Los Angeles real estate market.
                    Contact us today to learn more about our maintenance services and how they can benefit your property
                    investment.
                </Text>
                <Text>
                    In conclusion, our maintenance services at Los Angeles Property Management Pros are more than just
                    repairs and upkeep. They are about preserving and enhancing the value of your property, ensuring
                    tenant satisfaction, and providing you with the assurance that your Los Angeles property is in
                    expert hands. Choose us for maintenance services that go above and beyond, ensuring that your
                    property is not just maintained but thrives in the competitive Los Angeles market.
                </Text>
            </Stack>
        </Box>
    )
}