import React from "react";
import {Box, Heading, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const LegalServices = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Expert Legal Services by Los Angeles Property Management Pros</Heading>
                <Text>
                    At Los Angeles Property Management Pros, we understand that navigating the legal landscape of
                    property management can be complex and daunting. Our expert team is equipped to guide you through
                    all aspects of property laws and regulations, ensuring that your property remains compliant and
                    protected. In the intricate legal environment of Los Angeles, our legal services are designed to
                    provide peace of mind and safeguard your investment.
                </Text>
                <Text>
                    Our legal services cover a wide range of areas, from ensuring compliance with local and state laws
                    to providing expert legal counsel. We are committed to offering comprehensive legal support tailored
                    to the unique needs of your property.
                </Text>
                <Heading as={'h3'}>Comprehensive Legal Support for Property Management</Heading>
                <UnorderedList spacing={3}>
                    <ListItem><b>Ensuring Compliance:</b> We keep your property compliant with all relevant local,
                        state, and federal laws and regulations. Our team stays up-to-date with the latest legal
                        changes, ensuring your property adheres to current legal standards. This includes compliance in
                        areas such as tenant rights, safety regulations, and fair housing laws.</ListItem>
                    <ListItem><b>Providing Expert Legal Counsel:</b> Our legal experts offer advice to help you make
                        informed decisions about your property. Whether it’s navigating complex legal situations or
                        planning strategic moves, we provide the guidance you need to manage your property effectively
                        and legally.</ListItem>
                    <ListItem><b>Effective Dispute Resolution:</b> We offer mediation services to resolve conflicts
                        between landlords and tenants or other parties. Our approach to dispute resolution is aimed at
                        finding amicable solutions that minimize stress and legal costs while preserving relationships.</ListItem>
                    <ListItem><b>Lawful Eviction Services:</b> In cases where eviction becomes necessary, we handle the
                        process in compliance with state regulations. Our eviction services are conducted lawfully and
                        ethically, ensuring that your actions are legally sound and your property is
                        protected.</ListItem>
                </UnorderedList>
                <Text>
                    In addition to these core services, we also assist with drafting and reviewing lease agreements,
                    ensuring that they are legally robust and protect your interests. Our team works closely with you to
                    understand your specific needs and concerns, tailoring our legal services to your unique situation.
                </Text>
                <Text>
                    We believe in proactive legal management. This means not only addressing legal issues as they arise
                    but also implementing strategies to prevent potential legal problems. Our proactive approach
                    includes regular legal audits of your property, risk assessments, and training on legal matters
                    relevant to property management.
                </Text>
                <Text>
                    Our legal services extend to representing your interests in legal proceedings if necessary. Whether
                    it’s in court or other legal settings, we stand by you, offering representation and advocacy to
                    ensure your voice is heard and your rights are defended.
                </Text>
                <Text>
                    At Los Angeles Property Management Pros, our legal services are an integral part of our
                    comprehensive property management offerings. We understand the importance of legal compliance and
                    support in maintaining a successful rental property. With our expertise and support, you can
                    navigate the legal aspects of property management with confidence.
                </Text>
                <Text>
                    Choose Los Angeles Property Management Pros for legal services that are thorough, knowledgeable, and
                    tailored to the Los Angeles property market. Our team is ready to assist you in all legal aspects of
                    property management, ensuring that your property is not only compliant but also thriving. Contact us
                    today to learn more about how our legal services can support and enhance your property management
                    experience.
                </Text>
                <Text>
                    In conclusion, our legal services at Los Angeles Property Management Pros provide you with the
                    expertise and support needed to navigate the complex legal landscape of property management. From
                    compliance and legal counsel to dispute resolution and eviction services, we offer a range of legal
                    solutions to meet your needs. Let us help you manage the legal aspects of your property with
                    confidence and ease.
                </Text>
            </Stack>
        </Box>
    )
}