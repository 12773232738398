import $ from 'jquery';
import React, {useEffect} from 'react';
import PageHeader from '../Components/common/PageHeader';
import Banner from '../Containers/Banner';
import {SiteData} from "../Constants/siteData";
import {Box} from "@chakra-ui/react";

const faqs = [
    {
        "q": "What types of properties do you manage?",
        "a": "We manage a diverse range of properties including residential units like apartments and single-family homes, as well as commercial properties in West Hollywood and surrounding areas."
    },
    {
        "q": "How do you handle tenant screening?",
        "a": "Our tenant screening process is thorough and includes background checks, credit checks, employment verification, and previous rental history to ensure reliable and responsible tenants."
    },
    {
        "q": "What is your approach to property maintenance?",
        "a": "We prioritize preventive maintenance to keep properties in top condition. Our team responds promptly to repair requests and conducts regular inspections to address issues before they escalate."
    },
    {
        "q": "How do you ensure timely rent collection?",
        "a": "We implement efficient rent collection processes using online payment systems for convenience. We also enforce lease agreements strictly to ensure timely rent payments."
    },
    {
        "q": "Can you handle legal issues related to property management?",
        "a": "Yes, our team is well-versed in property laws and can handle legal matters including lease agreements, tenant disputes, and evictions, ensuring compliance and protecting your interests."
    },
    {
        "q": "Do you offer financial reporting for property owners?",
        "a": "Absolutely, we provide detailed monthly financial reports that include income and expense statements, helping property owners track their investment’s performance."
    },
    {
        "q": "What is your fee structure for property management services?",
        "a": "Our fee structure varies based on the type and number of properties managed. We offer competitive rates and can provide a detailed quote based on your specific property management needs."
    },
    {
        "q": "How do you handle emergency situations?",
        "a": "We have a 24/7 emergency response system in place to handle urgent maintenance issues. Our team is equipped to deal with emergencies quickly and effectively to minimize damage and disruption."
    },
    {
        "q": "What sets your property management services apart from others?",
        "a": "Our commitment to personalized service, extensive local market knowledge, and a proactive approach to property management set us apart. We focus on maximizing your returns while minimizing hassles."
    },
    {
        "q": "How can I get started with West Hollywood Property Managers?",
        "a": "Getting started is easy! Just contact us through our website or give us a call. We’ll discuss your property management needs and outline how we can help manage your property effectively."
    }
]

const FAQ = () => {
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);


    return (
        <>
            <PageHeader activeClass01='active' activeClass02='d-none' routeName01='FAQ'
                        header='Frequently Asked Questions' subtitle={'Let\'s give you some answers'}/>

            <div className="main_wrapper">
                <div className="faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-12">
                                <div className="images-faq">
                                    <img src="images/man.webp" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <Box py={20}>
                                    <div className="accordion">
                                        {faqs.map(({q, a}) => {
                                            return (
                                                <div className="item">
                                                    <div className="accordion_tab">
                                                        <h2 className="accordion_title">{q}</h2>
                                                        <span className="accordion_tab_icon">
                                                <i className="open_icon ion-ios-arrow-down"></i>
                                                <i className="close_icon ion-ios-arrow-up"></i>
                                            </span>
                                                    </div>
                                                    <div className="accordion_info">
                                                        {a}
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>

                <Banner
                    title={SiteData.contact.closingText}
                    heading='Call Us Anytime'
                    phone={SiteData.phoneNumber}
                    bannerType='banner banner_bg_color'
                />

            </div>
        </>
    );
};

export default FAQ;