import React from "react";
import {Box, Heading, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const TenantScreening = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Thorough Tenant Screening Services by Los Angeles Property Management Pros</Heading>
                <Text>
                    At Los Angeles Property Management Pros, we recognize that finding a reliable tenant is fundamental
                    to the success of your property investment. Our tenant screening process is designed to ensure that
                    you have tenants who are not only credible but also compatible with your property. In the
                    competitive Los Angeles rental market, our comprehensive screening process is a critical tool in
                    reducing risk and ensuring a stable income from your investment.
                </Text>
                <Text>
                    Our approach to tenant screening is meticulous and thorough, combining various checks and
                    verifications to build a complete profile of each applicant. This process is crucial in making
                    informed decisions and securing the best tenants for your property.
                </Text>
                <Heading as={'h2'}>Detailed Tenant Screening Process for Optimal Tenant Selection</Heading>
                <UnorderedList spacing={3}>
                    <ListItem><b>Extensive Background Checks:</b> Our background checks are comprehensive, covering
                        criminal history, previous rental history, and other relevant factors. These checks help in
                        verifying the credibility and reliability of each applicant, ensuring that your property is
                        leased to responsible tenants.</ListItem>
                    <ListItem><b>Rigorous Credit Score Analysis:</b> We conduct thorough financial screenings, including
                        credit score checks, to assess the financial stability and long-term viability of prospective
                        tenants. A tenant’s financial health is a strong indicator of their ability to consistently pay
                        rent on time.</ListItem>
                    <ListItem><b>Employment Verification:</b> Verifying employment status and income is a key part of
                        our screening process. We ensure that prospective tenants have a stable income source, which is
                        crucial for regular rent payments. This step includes contacting employers and reviewing income
                        documentation.</ListItem>
                    <ListItem><b>Detailed Reference Checks:</b> We go the extra mile by cross-verifying provided
                        references. This includes contacting previous landlords and other references to get a better
                        understanding of the tenant’s history and behavior, providing an additional layer of
                        security.</ListItem>
                </UnorderedList>
                <Text>
                    Our tenant screening services are not just about filtering out unsuitable applicants; they are about
                    finding the right match for your property. We take into consideration the unique aspects of your
                    property and your preferences as a property owner to find tenants who will respect and maintain your
                    investment.
                </Text>
                <Text>
                    In addition to the standard screening processes, we also consider factors like pet ownership,
                    smoking habits, and other lifestyle aspects that might impact their compatibility with your
                    property. Our goal is to ensure that the tenant not only meets financial and background criteria but
                    also fits well with the property’s community and environment.
                </Text>
                <Text>
                    We understand the importance of complying with all fair housing laws and regulations during the
                    tenant screening process. Our team is trained and knowledgeable about these regulations, ensuring
                    that all screenings are conducted legally and ethically.
                </Text>
                <Text>
                    At Los Angeles Property Management Pros, our tenant screening services are integral to our
                    comprehensive property management offerings. We take pride in our ability to provide property owners
                    with peace of mind, knowing that their property is occupied by thoroughly vetted and reliable
                    tenants. Our screening process is a testament to our commitment to protecting your investment and
                    maximizing its potential.
                </Text>
                <Text>
                    Choose Los Angeles Property Management Pros for tenant screening services that are thorough,
                    compliant, and tailored to meet the specific needs of your property. Our expertise in the Los
                    Angeles market, combined with our detailed screening process, ensures that your property is leased
                    to the best possible tenants. Contact us today to learn more about how our tenant screening services
                    can contribute to the success and stability of your rental property.
                </Text>
                <Text>
                    In conclusion, our tenant screening services at Los Angeles Property Management Pros are designed to
                    mitigate risk and ensure the longevity of your property investment. We provide a comprehensive
                    screening process that encompasses financial, background, employment, and reference checks, all
                    conducted with the utmost professionalism and attention to detail. Let us help you secure the best
                    tenants for your property in the competitive Los Angeles rental market.
                </Text>
            </Stack>
        </Box>
    )
}